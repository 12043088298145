import Home from './Home';
import About from './About';
import HowItWorks from './HowItWorks';
import SingleProperty  from './SingleProperty';
import Error from './Error';
import Register from './Register';
import Login from './Login';
import Verify from './Verify';
import Dashboard from './Dashboard';
import ProtectedRoute from '../components/routing/ProtectedRoute';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

export {
    Home,
    About,
    HowItWorks,
    SingleProperty,
    Error,
    Register,
    Login,
    Verify,
    Dashboard,
    ProtectedRoute,
    ForgotPassword,
    ResetPassword,
};
