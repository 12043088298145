import React from 'react'

export default function HowItWorks(){

    return(
        <section className="section about-section">
            <h1 className="section-title">How It Works</h1>
            <p>
                Our web site will match both buyers and sellers who are 
                looking to buy or sell in the neighborhoods selected by 
                them through our web site.  Through this website, subscribers
                will be able to contact buyers/sellers via e-mail and answer 
                questions about the marketed property, entertain offers, 
                negotiate price and terms of the offers.  Subscribers will
                be able to reach and execute a contract where they can choose
                the moving and closing dates.  In general, buyers and sellers 
                will have the freedom to drive their properties' closing
                process the way they want, at the pace they want.  It's like
                an online dating service, but for properties 
            </p>
        </section>
    )
}


