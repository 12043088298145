import React from 'react'
import { useEffect, useRef, useState, useContext } from 'react'
// import { useGlobalContext } from '../context/context'
import AuthContext from '../context/auth/AuthContext';

export default function SearchForm() {
    // const { setSearchTerm } = useGlobalContext()

    const authContext = useContext(AuthContext)
    const { setSearchTerm } = authContext 
    const [text, setText] = useState('')
    const searchValue = useRef('')

    useEffect(() => {
        searchValue.current.focus()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (text) {
            setSearchTerm(text)
        }
    }

    const handleChange = (e) => {
        setText(e.target.value)
    }

    return (
        <section className='section search'>
            <form className='search-form' onSubmit={handleSubmit}>
                <div className='form-control'>
                    <label htmlFor='name'>search for a property</label>
                    <input type='text' onChange={handleChange} value={text} placeholder="Start your search" className='form-input' ref={searchValue} />
                    <button type="submit" className='btn'>Search</button>
                </div>
            </form>
        </section>
    )
}