import {
    REGISTER_SUCCESS,
    VERIFY_FAIL,
    REGISTER_FAIL,
    USER_LOADED,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../actions';

export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
          return {
            ...state,
            isAuthenticated: true,
            loading: false,
            user: action.payload
          };
        case RESET_PASSWORD:
          return {
            ...state,
            ...action.payload,
            isAuthenticated: false,
            loading: false,
          }          
        case FORGOT_PASSWORD:
          return {
            ...state,
            ...action.payload,
            isAuthenticated: true,
            loading: false,
            alertType: "success",
            alertText: action.payload.alertText
          }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
          localStorage.setItem("token", action.payload.token);
          return {
            ...state,
            ...action.payload,
            isAuthenticated: true,
            loading: false,
            alertType: "success",
            alertText: action.payload.alertText
          };
        case VERIFY_FAIL:
        case REGISTER_FAIL:
        case RESET_PASSWORD_FAIL:
        case FORGOT_PASSWORD_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
          localStorage.removeItem("token");
          return {
            ...state,
            token: null,
            isAuthenticated: false,
            loading: false,
            user: null,
            error: action.payload
          };
        case CLEAR_ERRORS:
          return {
            ...state,
            alertType: "",
            alertText: "",
            error: null
          };
        default:
          return state;
      }
};