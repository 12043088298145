import React from 'react'
import PropertyList from '../components/PropertyList'
import SearchForm from '../components/SearchForm'

export default function Home(){
    return (
        <main>
            <SearchForm />
            <PropertyList />
        </main>
    )
}