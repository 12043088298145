import React, { useState, useEffect, useRef, useContext } from 'react'
import { Logo, FormRow, Alert} from '../components'
import Wrapper from '../assets/wrappers/RegisterPage'
import { Link, useNavigate } from 'react-router-dom'
import useLocalState from '../utils/localState';
import AuthContext from '../context/auth/AuthContext';

const initialState = {
    fname: '',
    lname: '',
    email: '',
    password: '',
    isMember: true,
    ref: ''
}

const Register = () => {

    const authContext = useContext(AuthContext)
    const { register, isAuthenticated, error, user, msg } = authContext

    const fnameField = useRef('')
    const navigate = useNavigate()
    const [values, setValues] = useState(initialState)

    const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

    useEffect(() => {
        if (isAuthenticated) {
            showAlert({
                text: msg,
                type: 'success',
            });
            // navigate('/dashboard')
        }
        if (error) {
            // setAlert(error, "danger");
            showAlert({ text: error, type: "danger"})
            setTimeout(() => hideAlert(), 5000);
        }
        
        // eslint-disable-next-line
    }, [error, isAuthenticated]);

    const toggleMember = () => {
        setValues({ ...values, isMember: !values.isMember })
    }
    
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault() 
        hideAlert();
        setLoading(true);
        const { fname, lname, email, password, password2, isMember } = values
        const registerNewUser = { fname, lname, email, password };

        if (!email || !password || (!isMember && !fname)){
            showAlert({text: "Please enter all the values requested", type: "danger"})
            return
        }else if (password !== password2) {
            showAlert({ text: "Passwords do not match", type: "danger"})
            return
        }

        try {
            register(registerNewUser)
            // setSuccess(true);
            setValues({ fname: '', lname: '', email: '', password: '' });
        } catch (error) {
            const { msg } = error.response.data;
            showAlert({ text: msg || 'there was an error' });
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if (user) {
                setTimeout(() => {
                navigate('/')
            }, 5000)
        }
    }, [user, navigate])
    
    return (
        <Wrapper className='full-page'>
            {alert.show && (
                <div className={`alert alert-${alert.type}`}>{alert.text}</div>
            )}
            {/* {!success && ( */}
            {/* {!isAuthenticated && ( */}
            {!isAuthenticated && !alert.show && (
                <form className='form' onSubmit={onSubmit}>
                    <Logo />
                    <h3>Register</h3>
                    {showAlert && <Alert />}
                    {/* name input */}
                    <FormRow
                        type='text'
                        name='fname'
                        labelText={"first name"}
                        value={values.fname}
                        handleChange={handleChange}
                        refValue={fnameField} 
                    />
                    <FormRow
                        type='text'
                        name='lname'
                        labelText={"last name"}
                        value={values.lname}
                        handleChange={handleChange}
                    />
                    {/* email input */}
                    <FormRow
                        type='email'
                        name='email'
                        value={values.email}
                        handleChange={handleChange}
                    />
                    {/* password input */}
                    <FormRow
                        type='password'
                        name='password'
                        value={values.password}
                        handleChange={handleChange}
                    />
                    <FormRow
                        type='password'
                        name='password2'
                        labelText={"Confirm Password"}
                        value={values.password2}
                        handleChange={handleChange}
                    />
                    <button type='submit' className='btn btn-block' disabled={loading}>
                        {loading ? 'Loading...' : 'Register'}
                    </button>
                    <p>
                        Already a have an account?&nbsp;
                        <Link to='/login' className='login-link'>
                            Log In
                        </Link>
                    </p>
                    <p>
                        Forgot your password? {' '}
                        <Link to='/forgot-password' className='reset-link'>
                            Reset Password
                        </Link>
                    </p>
                </form>
            )}
        </Wrapper>
    )
}

export default Register
