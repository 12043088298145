import React, { useReducer, useContext } from 'react'

import reducer from './reducer'
import axios from 'axios'
import {
    DISPLAY_ALERT,
    CLEAR_ALERT,
    SETUP_USER_BEGIN,
    SETUP_USER_SUCCESS,
    SETUP_USER_ERROR,
} from './actions'

const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
const userLocation = localStorage.getItem('location')

const initialState = {
    isLoading: false,
    showAlert: false,
    alertText: '',
    alertType: '',
    user: user ? JSON.parse(user) : null,
    token: token,
    userLocation: userLocation || '',
    showSidebar: false,
    isEditing: false,
    editJobId: '',
    position: '',
    company: '',
    jobLocation: userLocation || '',
    jobTypeOptions: ['full-time', 'part-time', 'remote', 'internship'],
    jobType: 'full-time',
    statusOptions: ['interview', 'declined', 'pending'],
    status: 'pending',
    jobs: [],
    totalJobs: 0,
    numOfPages: 1,
    page: 1,
    stats: {},
    monthlyApplications: [],
    search: '',
    searchStatus: 'all',
    searchType: 'all',
    sort: 'latest',
    sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(reducer, initialState)

    // Axios
    // const authFetch = axios.create({
    //     baseURL: '/api/v1'
    // })
    const authFetch = axios.create({
        // baseURL: 'https://novazao-api-v2.herokuapp.com/api/v1'
        // baseURL: 'http://localhost:5000/api/v1'
        baseURL: process.env.REACT_APP_API_URL
    })

    // Request


    // Repsonse
    
    const displayAlert = () => {
        dispatch({ type: DISPLAY_ALERT})
        clearAlert()
    }

    const clearAlert = () => {
        setTimeout(() => {
            dispatch({ type: CLEAR_ALERT})
        }, 3000)
    }

    const setupUser = async ({ currentUser, endPoint, alertText }) => {
        dispatch({ type: SETUP_USER_BEGIN})
        try {
            const { data } = await axios.post(`/api/v1/auth/${endPoint}`, currentUser)
  
            const { user, token, location } = data
            dispatch({
                type: SETUP_USER_SUCCESS,
                payload: { user, token, location, alertText }
            })
            addUserToLocalStorage({user, token, location})
        }catch(error){
            dispatch({
                type: SETUP_USER_ERROR,
                payload: { msg: error.response.data.msg },
            })
        }
        clearAlert()
    }

    const addUserToLocalStorage = ({ user, token, location }) => {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('token', token)
        localStorage.setItem('location', location)
    }

    return(
        <AppContext.Provider
            value={{
                ...state,
                displayAlert,
                setupUser,

            }}
        >
            {children}
        </AppContext.Provider>
    )

}

const useAppContext = () => {
    return useContext(AppContext)
}
/*
export { AppProvider, initialState, useAppContext }
*/
/*
export const useGlobalContext = () => {
    return useContext(AppContext)
}
*/
export { AppProvider, initialState, useAppContext  }
