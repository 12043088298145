import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth/AuthContext';

const ProtectedRoute = ({ children, ...rest }) => {
    const authContext  = useContext(AuthContext)

    const { isAuthenticated } = authContext;

    return (
        isAuthenticated ? <Outlet /> : <Navigate to='/login' />
    )
};

export default ProtectedRoute;