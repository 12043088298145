import React, { useContext } from 'react'
import Property from './Property'
import Loading from './Loading'
// import { useGlobalContext } from '../context/context'
import AuthContext from '../context/auth/AuthContext';

export default function PropertyList() {
    // const {properties, loading } = useGlobalContext()

    const authContext = useContext(AuthContext)
    const { properties, loading } = authContext 

    if (loading){
        return <Loading />
    }

    if (properties.length < 1){
        return (
            <h2 className='section-title'>
                no properties matched your search criteria
            </h2>
        )
    }

    return (
        <section className='section'>
            <div className='properties-center'>
                {properties.map((item) => {
                    return <Property key={item.id} {...item} />
                })}
            </div>
        </section>
    )
}