import React from 'react'
import { useState, useEffect } from 'react'
import Loading from '../components/Loading'
import { useParams, Link } from 'react-router-dom'

export default function SingleProperty(){
    const { id } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ property, setProperty ] = useState(null)

    useEffect(() => {
        setLoading(true)
        async function getProperty(){
            try{
                const response = await fetch(
                    // `http://localhost:5000/api/v1/properties/${id}`
                    // `https://novazao-api-v2.herokuapp.com/api/v1/properties/${id}`
                    process.env.REACT_APP_API_URL
                )
                const data = await response.json()
                console.log("**********in Single Property ************")
                console.log(data)
                if (data.property){
                    const {
                        strDrink: address,
                        strDrinkThumb: propertyThumb,
                        strAlcoholic: description,
                        strCategory: status,
                        strCity: city,
                        strGlass: dateModified,
                        strCreatedAt: createdAt,
                    } = data.property

                    const newProperty = {
                        address: data.property.address,
                        propertyThumb: data.property.propertyThumb,
                        description: data.property.description,
                        status: data.property.status,
                        city: data.property.city,
                        dateModified: data.property.dateModified,
                        createdAt: data.property.createdAt,
                    } 

                    setProperty(newProperty)
                }else{
                    setProperty(null)
                }
            }catch(error){
                console.log(error)
            }
            setLoading(false)
        }
        getProperty()
    }, [id])

    if (loading){
        return <Loading />
    }

    if (!property){
        return <h2 className='section-title'>no property to display</h2>
    }else{
        const {
            address,
            city,
            description,
            propertyThumb,
            status,
            createdAt,
            dateModified,
        } = property

        console.log("kevin kevin kevin")
        console.log(property)
        return (
            <section className='section property-section'>
                <Link to='/' className='btn btn-primary'>
                    back home
                </Link>
                <h2 className='section-title'>{description}</h2>
                <div className='drink'>
                    <img src={propertyThumb} alt={description}></img>
                    <div className='drink-info'>
                        <p>
                            <span className='drink-data'>address :</span> {address}
                        </p>
                        <p>
                            <span className='drink-data'>description :</span> {description}
                        </p>
                        <p>
                            <span className='drink-data'>status :</span> {status}
                        </p>
                        <p>
                            <span className='drink-data'>city :</span> {city}
                        </p>
                        <p>
                            <span className='drink-data'>Details :</span>
                            {/*  
                            {ingredients.map((item, index) => {
                                return item ? <span key={index}> {item}</span> : null
                            })}
                            */}
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}