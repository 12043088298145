import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Home,
  About,
  HowItWorks,
  SingleProperty,
  Error,
  Register,
  Login,
  Verify,
  Dashboard,
  ForgotPassword,
  ResetPassword,
} from './pages';

import AuthState from "./context/auth/AuthState"
// import PrivateRoute from "./components/routing/PrivateRoute";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import Navbar from "./components/Navbar"
// import { useGlobalContext } from './context/context';

function App() {

  return (
    <Router>
      <AuthState>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/user/verify-email' element={<Verify />} />
          <Route path='/user/reset-password' element={<ResetPassword />} />
          <Route path="/about" element={<About /> } />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/property/:id" element={<SingleProperty />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AuthState>
    </Router>
  );
}

export default App;
