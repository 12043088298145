import React, { useState, useEffect, useRef, useContext }  from 'react';
import { Logo, FormRow } from '../components';
import Wrapper from '../assets/wrappers/RegisterPage';
import { Link, useNavigate } from 'react-router-dom';
import useLocalState from '../utils/localState';
import AuthContext from '../context/auth/AuthContext'

const Login = (props) => {
  const authContext = useContext(AuthContext)
  const { login, error, isAuthenticated, clearErrors } = authContext

  const emailField = useRef('')
  const navigate = useNavigate();

  useEffect(() => {
    emailField.current.focus();
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      showAlert({
        text: `Welcome, Redirecting to dashboard...`,
        type: 'success',
      });
      navigate('/dashboard')
    }
    if (error) {
      showAlert({ text: error, type: "danger"})
      setTimeout(() => hideAlert(), 5000);
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated]);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const { alert, showAlert, loading, setLoading, hideAlert } = useLocalState();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  
  const onSubmit = e => {
    e.preventDefault();
    hideAlert();
    clearErrors();
    setLoading(true);
    const { email, password } = values;
    const userInfo = { email, password };

    try {
      login(userInfo)
      setLoading(false);
    } catch (error) {
      showAlert({ text: error.response.data.msg });
      setLoading(false);
    }
  };

  return (
      <Wrapper className='full-page'>
        {alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
        <form className={loading ? 'form form-loading' : 'form'} onSubmit={onSubmit}>
          <Logo />
          {/* single form row */}
          <FormRow
            type='email'
            name='email'
            value={values.email}
            handleChange={handleChange}
            refValue={emailField}
          />
          {/* end of single form row */}
          {/* single form row */}
          <FormRow
            type='password'
            name='password'
            value={values.password}
            handleChange={handleChange}
          />
          {/* end of single form row */}
          <button type='submit' className='btn btn-block' disabled={loading}>
            {loading ? 'Loading...' : 'Login'}
          </button>
          <p>
            Don't have an account?
            <Link to='/register' className='register-link'>
              Register
            </Link>
          </p>
          <p>
            Forgot your password?{' '}
            <Link to='/forgot-password' className='reset-link'>
              Reset Password
            </Link>
          </p>
        </form>
      </Wrapper>
  );
}

export default Login;
