import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FormRow from '../components/FormRow'
import useLocalState from '../utils/localState'
import AuthContext from '../context/auth/AuthContext'

const ForgotPassword = () => {
    const authContext = useContext(AuthContext)
    const { forgotPassword, isAuthenticated, error, msg, user, clearErrors } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            showAlert({
                text: msg,
                type: 'success',
            });
            // navigate('/dashboard')
        }
        if (error) {
            // setAlert(error, "danger");
            showAlert({ text: error, type: "danger"})
            setTimeout(() => hideAlert(), 5000);
        }
        
        // eslint-disable-next-line
    }, [error, isAuthenticated]);

    const [email, setEmail] = useState('');
    const {
        alert,
        showAlert,
        loading,
        setLoading,
        success,
        setSuccess,
        hideAlert,
    } = useLocalState();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        hideAlert();
        clearErrors();
        setLoading(true);

        if (!email) {
            showAlert({
                text: 'Please provide email'
            });
            setLoading(false)
            setTimeout(() => hideAlert(), 3000)
            return;
        }
        try {;
            forgotPassword({email})
            setSuccess(true)
        } catch(error) {
            const { msg } = error.response.data;
            showAlert({ text: msg || 'there was an error' });
        }
        setLoading(false)
    };
    return (
        <Wrapper className='page'>
            {alert.show && (
                <div className={`alert alert-${alert.type}`}>{alert.text}</div>
            )}
            {!success && (
                <form className={loading ? 'form form-loading' : 'form'}onSubmit={handleSubmit}>
                    <h4>Forgot Password</h4>
                    {/* Single form row */}
                    <FormRow
                        type='email'
                        name='email'
                        value={email}
                        handleChange={handleChange}
                    />
                    {/* End of single form row */}
                    <button type='submit' className='btn btn-block' disabled={loading}>
                        {loading ? 'Please wait...' : 'Get Reset Password Link'}
                    </button>
                    <p>
                        Already have an account?
                        <Link to='/login' className='login-link'>
                            Log In
                        </Link>
                    </p>
                </form>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.main`
    h4,
    p {
        text-align: center;
    }
    p {
        margin: 0;
        margin-top: 1rem;
    }
    .login-link {
        display: inline-block;
        margin-left: 0.25rem;
        text-transform: capitalize;
        color: var(--primary-500);
        cursor: pointer;
    }
`;

export default ForgotPassword;