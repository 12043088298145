import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom' 
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    VERIFY_FAIL,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD,
    RESET_PASSWORD_FAIL,
} from '../actions';
import Cookies from "universal-cookie";
import axiosInstance from '../../api/axios';
import Spinner from "../../components/layout/Spinner"

const cookies = new Cookies();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AuthState = props => {
    const initialState = {
        token_old: localStorage.getItem("token"),
        token: cookies.get("accessToken"), 
        isAuthenticated: null,
        alertText: '',
        alertType: '',
        loading: true,
        user: null,
        error: null,
        msg: null
    };
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')
    const [properties, setProperties] = useState([])
    const [user, setUser] = useState(null)
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    const query = useQuery();

    const removeUser = () => {
        setUser(null);
    }

    const loadUser = async () => {
        try {
            const res = await axiosInstance.get(`/users/showMe`)

            dispatch({
                type: USER_LOADED,
                payload: res.data.user
            });
        } catch (err) {
            dispatch({ type: AUTH_ERROR });
        }
    };

    // Register user
    const register = async formData => {
        setLoading(true)
        try {
            const res = await axiosInstance.post(`/auth/register`, formData)
            
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            });
            loadUser();
        } catch (err) {
            dispatch({
                type: REGISTER_FAIL,
                payload: err.response.data.msg
            });
        }
        setLoading(false)
    };

    // Forgot Password
    const forgotPassword = async formData => {
        setLoading(true)
        try {
            const res = await axiosInstance.post(`/auth/forgot-password`, formData)

            dispatch({
                type: FORGOT_PASSWORD,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: FORGOT_PASSWORD_FAIL,
                payload: err.response.data.msg
            })
        }
        setLoading(false)
    }

    // Reset Password
    const resetPassword = async formData => {
        setLoading(true)
        try {
            const res = axiosInstance.post(`/auth/reset-password`, formData)

            dispatch({
                type: RESET_PASSWORD,
                payload: res.data
            })
        } catch (err) {
            dispatch({
                type: RESET_PASSWORD_FAIL,
                payload: err.response.data.msg
            })
        }
        setLoading(false)
    }

    // Login
    const login = async formData => {

        setLoading(true)
        try {
            
            const res = await axiosInstance.post(`/auth/login`, formData)
            
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
            loadUser();
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data.msg
            });
        }
        setLoading(false)
    };

    // Verify Email
    const verifyEmail = async () => {
        try {   
            await axiosInstance.post(`/auth/verify-email`, {
                verificationToken: query.get('token'),
                email: query.get('email'),
            })
        } catch(err) {
            dispatch({
                type: VERIFY_FAIL,
                payload: err.response.data.msg
            })
        }
    }

    // Logout
    const logout = async () => {
        await axiosInstance.delete(('/auth/logout'))
        
        removeUser()
        dispatch({ type: LOGOUT });
    };

    //Clear Error
    const clearErrors = () => {
        dispatch({ type: CLEAR_ERRORS });
    };

    const fetchProperties = useCallback(async () => {
        setLoading(true)
        try{
            const response = await axiosInstance.get(`/properties?search=${searchTerm}`);

            const data = await response.data

            const { properties } = data

            if (properties) {
                const newProperties = properties.map((item) => {
                    const {
                        _id,
                        address,
                        propertyThumb,
                        description,
                        status,
                    } = item

                    return {
                        id: _id,
                        name: address,
                        image: propertyThumb,
                        info: description,
                        glass: status,
                    }
                })
                setProperties(newProperties)

            }else{
                setProperties([])
            }
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)

        }
    }, [searchTerm])
    
    useEffect(() => {
        fetchProperties()
    }, [searchTerm, fetchProperties])

    useEffect(() => {
        loadUser()
    }, []);

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                loading: state.loading,
                user: state.user,
                error: state.error,
                msg: state.msg,
                properties, 
                searchTerm, 
                register,
                forgotPassword,
                resetPassword,
                loadUser,
                login,
                logout,
                verifyEmail,
                setSearchTerm, 
                clearErrors
            }}
        >
            {loading ? <Spinner /> : props.children }
        </AuthContext.Provider>
    );
};

export default AuthState;