import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import FormRow from '../components/FormRow';
import useLocalState from '../utils/localState';
import Wrapper from '../assets/wrappers/RegisterPage';
import AuthContext from '../context/auth/AuthContext'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPasswordForm = () => {
    const authContext = useContext(AuthContext)
    const { resetPassword, isAuthenticated, error, user, msg } = authContext

    const navigate = useNavigate();
    const [password, setPassword] = useState('')
    const { alert, showAlert, loading, setLoading, success, setSuccess, hideAlert} = useLocalState();

    const query = useQuery();

    const handleChange = async (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        hideAlert();
        setLoading(true);
        if (!password) {
            showAlert({ text: 'please enter password'});
            setLoading(false);
            return;
        }
        try {
            resetPassword({
                password,
                token: query.get('token'),
                email: query.get('email'),
            })
            setLoading(false);
            setSuccess(true);
            showAlert({ 
                text: `Success, redirecting to login page shortly`,
                type: 'success',
            });
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            showAlert({ text: error.response.data.msg });
            setLoading(false);
        }
    };

    return (
        <Wrapper className='page'>
            {alert.show && (
                <div className={`alert alert-${alert.type}`}>{alert.text}</div>
            )}
            {!success && (
                <form 
                    className={loading ? 'form form-loading' : 'form'}
                    onSubmit={handleSubmit}
                >
                    <h4>reset password</h4>
                    {/* Single row form */}
                    <FormRow 
                        type='password'
                        name='password'
                        value={password}
                        handleChange={handleChange}
                    />
                    {/* End of single form row */}
                    <button type='submit' className='btn btn-block' disabled={loading}>
                        {loading ? 'Please wait...' : 'New Password'}
                    </button>
                </form>
            )}
        </Wrapper>
    );
};

// const Wrapper = styled.section`
//     h4, 
//     p {
//         text-align: center;
//     }
//     p {
//         margin: 0;
//         margin-top: 1rem;
//     }
// `;

export default ResetPasswordForm;