import React, { useContext } from 'react'
// import { useGlobalContext } from '../context/context'
import AuthContext from '../context/auth/AuthContext'

const Alert = () => {
    // const { alertType, alertText } = useGlobalContext()

    const authContext = useContext(AuthContext)
    const { alertType, alertText } = authContext

    return <div className={`alert alert-${alertType}`}>{alertText}</div>
}

export default Alert