import React, { useState, useEffect, useContext, useReducer, useCallback } from 'react'
import axiosInstance from '../api/axios'
import reducer from './reducer'

import {
    DISPLAY_ALERT,
    CLEAR_ALERT,
    SETUP_USER_BEGIN,
    SETUP_USER_SUCCESS,
    SETUP_USER_ERROR,
    TOGGLE_SIDEBAR,
    LOGOUT_USER,
    UPDATE_USER_BEGIN,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    HANDLE_CHANGE,
    CLEAR_VALUES,
} from './actions'

const token = localStorage.getItem('token')
// const user = localStorage.getItem('user')
const userLocation = localStorage.getItem('location')

const initialState = {
    isLoading: false,
    showAlert: false,
    alertText: '',
    alertType: '',
    // user: null,
    token: token,
    userLocation: userLocation || '',
    showSidebar: false,
    isEditing: false,
    editJobId: '',
    position: '',
    company: '',
    jobLocation: userLocation || '',
    jobTypeOptions: ['full-time', 'part-time', 'remote', 'internship'],
    jobType: 'full-time',
    statusOptions: ['interview', 'declined', 'pending'],
    status: 'pending',
    jobs: [],
    totalJobs: 0,
    numOfPages: 1,
    page: 1,
    stats: {},
    monthlyApplications: [],
    search: '',
    searchStatus: 'all',
    searchType: 'all',
    sort: 'latest',
    sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('')
    const [properties, setProperties] = useState([])
    const [state, dispatch] = useReducer(reducer, initialState)
    const [user, setUser] = useState(null)
    const [success, setSuccess] = useState(false)
    const [alert, setAlert] = useState({
        show: false,
        text: '',
        type: 'danger',
    });

    const saveUser = (user) => {
        setUser(user)
    }

    const removeUser = () => {
        setUser(null);
    }

    const displayAlert = () => {
        dispatch({ type: DISPLAY_ALERT})
        clearAlert()
    }

    const clearAlert = () => {
        setTimeout(() => {
            dispatch({ type: CLEAR_ALERT})
        }, 3000)
    }

    const showAlert = ({ text, type = 'danger' }) => {
        setAlert({ show: true, text, type });
    };
    const hideAlert = () => {
        setAlert({ show: false, text: '', type: 'danger' });
    };

    // const addUserToLocalStorage = ({user, token, location}) => {
    //     localStorage.setItem('user', JSON.stringify(user))
    //     localStorage.setItem('token', token)
    //     localStorage.setItem('location', location)
    // }

    // const removeUserFromLocalStorage = () => {
    //     localStorage.removeItem('token')
    //     localStorage.removeItem('user')
    //     localStorage.removeItem('location')
    // }

    const setupUser = async ({ currentUser, endPoint, alertText }) => {
        dispatch({ type: SETUP_USER_BEGIN})
        try {
            const { data } = await axiosInstance.post(`/auth/${endPoint}`, currentUser)
            
            const { user, token, location } = data 
            dispatch({
                type: SETUP_USER_SUCCESS,
                payload: { user, token, location, alertText }
            }) 
            // addUserToLocalStorage({user, token, location})
        }catch(error){
            dispatch({
                type: SETUP_USER_ERROR,
                payload: { msg: error.response.data.msg},
            })
        }
        clearAlert()
    }

    const handleChange = ({name, value }) => {
        dispatch({ type: HANDLE_CHANGE, payload: {name, value } })
    }

    const toggleSidebar = () => {
        dispatch({ type: TOGGLE_SIDEBAR })
    }

    const fetchUser = async () => {
        try {
            console.log("in fetchUser in context.js")
            // const { data } = await axios.get(`/api/v1/users/showMe`);
            const { data } = await axiosInstance.get(`/users/showMe`);
            console.log("data user:", data)
            console.log("*************** fetchUser ***************")
            saveUser(data.user)
            console.log("*************** fetchUser: After saveUSer ***************")
            
        } catch (error) {
            removeUser()
        }
        setLoading(false);
    }

    const logoutUser = async () => {
        try {
            console.log("logoutUser from the context.js file instead of the AuthContext!!!")
            await axiosInstance.delete(('/auth/logout'))
            removeUser()
        } catch (error) {
            console.log(error);
        }

        dispatch({ type: LOGOUT_USER })
        // removeUserFromLocalStorage()
    }

    const fetchProperties = useCallback(async () => {
        setLoading(true)
        try{
            const response = await axiosInstance.get(`/properties?search=${searchTerm}`);

            const data = await response.data

            const { properties } = data

            if (properties) {
                const newProperties = properties.map((item) => {
                    const {
                        _id,
                        address,
                        propertyThumb,
                        description,
                        status,
                    } = item

                    return {
                        id: _id,
                        name: address,
                        image: propertyThumb,
                        info: description,
                        glass: status,
                    }
                })
                setProperties(newProperties)

            }else{
                setProperties([])
            }
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)

        }
    }, [searchTerm])
    
    useEffect(() => {
        fetchProperties()
    }, [searchTerm, fetchProperties])

    useEffect(() => {
        fetchUser();
    }, []);

    return(
        <AppContext.Provider
            value={{ 
                ...state,
                alert,
                showAlert,
                loading,
                setLoading,
                properties, 
                searchTerm, 
                saveUser,
                user,
                displayAlert, 
                // setupUser,
                logoutUser,
                setSearchTerm, 
                handleChange,
                success,
                setSuccess,
                hideAlert,
            }}
        >
            { children }
        </AppContext.Provider>
    )
}

const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppProvider, initialState, useGlobalContext }
