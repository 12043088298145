import React, { useContext } from 'react';
import Wrapper from '../assets/wrappers/RegisterPage';
import AuthContext from '../context/auth/AuthContext';

const Dashboard = () => {
    const authContext = useContext(AuthContext)
    const { user } = authContext 
    const { fname, userId, role } = user;    

    return (
        <>
            <Wrapper className='page'>
                <h2>Hello there, {fname}</h2>
                <p>
                    Your ID: <span>{userId}</span>
                </p>
                <p>
                    Your role: <span>{role}</span>
                </p>
            </Wrapper>
        </>
    )
}

export default Dashboard;