import React from 'react'
import { Link } from 'react-router-dom'

export default function Property({ image, name, id, info, glass }){
    return (
        <article className='property'>
            <div className='img-container'>
                <img src={image} alt={name} />
            </div>
            <div className='property-footer'>
                <h3>{name}</h3>
                <h4>{glass}</h4>
                <p>{info}</p>
                <Link to={`/property/${id}`} className='btn btn-primary btn-details' >
                    details
                </Link>
            </div>
        </article>
    )
}
