import React, { useState, Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/novazao_logo.svg'
import Wrapper from '../assets/wrappers/RegisterPage'
import { FaAlignLeft, FaUserCircle, FaCaretDown, FaSignOutAlt } from 'react-icons/fa'
import { useGlobalContext } from '../context/context'
import AuthContext from '../context/auth/AuthContext';

const Navbar = () => {
    const [showLogout, setShowLogout] = useState(false)
    // const { user, logoutUser } = useGlobalContext() 

    const authContext = useContext(AuthContext)
    const { user, logout } = authContext 

    function toggleLogout(){
        if (user){
            // logoutUser()
            logout()
            setShowLogout(false)
        }
    }

    const onLogout = () => {
        setShowLogout(false)
        // logoutUser()
        logout()
    }

    const authLinks = (
        <Fragment>
            {/* 
            <li>Hello {user && user.name}</li>
             */}
            {/*  
            <li onClick={() => setShowLogout(!showLogout)} className='loginName'>
                <FaUserCircle />
                {user?.name}
                <FaCaretDown />
            </li>
            <div className='btn-container'>
                <div className={showLogout ? 'dropdown show-dropdown' : 'dropdown'}>
                    <button type='button' className='dropdown-btn' onClick={onLogout}>
                    logout
                    </button>
                </div>
            </div>
            */}
            {/*   
            <li>
                <a onClick={onLogout} href='/'>
                    <FaSignOutAlt />                    
                    <span className='hide-sm'>Logout</span>
                </a>
            </li>
            */}

            <li>Hello {user && user.fname}</li>
            <li>
                <Link onClick={onLogout} to='/login'>
                <i className='fas fa-sign-out-alt' />{' '}
                <span className='hide-sm'>Logout</span>
                </Link>
            </li>
        </Fragment>
    );

    const guestLinks = (
        <Fragment>
            <li>
                {/* <Link to='/register' onClick={toggleLogout} alt={user?.name}>{user ? 'Logout': 'Sign In'}</Link> */}
                {/* <Link to='/login' onClick={toggleLogout} alt={user?.name}>{user ? 'Logout': 'Sign In'}</Link> */}
                <Link to='/login' onClick={toggleLogout} alt={user ? user.name : "no one"}>{user ? 'Logout': 'Sign In'}</Link>
            </li>
        </Fragment>
    );

    return (
        <Wrapper>
            <nav className='navbar'>
                <div className='nav-center'>
                    <div className='logo-div'>
                        <Link to='/'>
                            <img src={logo} alt='Novazao Logo' className='logo' />
                        </Link>
                        novazao
                    </div>
                    <ul className='nav-links'>
                        <li>
                            <Link to='/'>home</Link>
                        </li>
                        <li>
                            <Link to='/about'>about</Link>
                        </li>
                        <li>
                            <Link to='/howitworks'>how it works</Link>
                        </li>
                        { user ? 
                            <li>
                            <Link to='/dashboard'>dashboard</Link>
                        </li> : <></>
                        }
                        {user ? authLinks : guestLinks}
                    </ul>
                </div>
            </nav>
        </Wrapper>
    )
}

export default Navbar