export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const HANDLE_CHANGE = 'HANDLE_CHANGE'
export const CLEAR_VALUES = 'CLEAR_VALUES'

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const USER_LIST = "USER_LIST";
export const VERIFY_FAIL = "VERIFY_FAIL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
// export const FLOWER_LIST = "FLOWER_LIST";
// export const FLOWER_ADD = "FLOWER_ADD";
// export const FLOWER_EDIT = "FLOWER_EDIT";
// export const FLOWER_DELETE = "FLWOER_DELETE";
// export const FLOWER_ERROR = "FLOWER_ERROR";
