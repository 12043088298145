import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL

const axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content, Accept, Content-Type, Authorization, Accept, Set-Cookie",
    }
});

export default axiosInstance