import React from 'react'

export default function About(){
    return (
        <section className='section about-section'>
            <h1 className='section-title'>about us</h1>
            <p>
            Novazao was born out of a need we saw in the Real Estate industry
            that affect us all if we are in the market to buy, sell a property. 

            The goal of Novazao is
            to democratize the Real Estate process by being able to sell your 
            home without paying high commissions on the money you'll receive 
            when selling your property.  And to have the convenience of buying 
            your heaven on earth on your terms.  You set the price, the time, 
            and the terms to close.
            </p>
        </section>
    )
}