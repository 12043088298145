import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom' 
import AuthContext from '../context/auth/AuthContext'
import useLocalState from '../utils/localState';
import Wrapper from '../assets/wrappers/RegisterPage'

const VerifyPage = () => {
    const { loading, setLoading } = useLocalState();
    const authContext = useContext(AuthContext)
    const { verifyEmail } = authContext

    const verifyToken = async () => {
        setLoading(true)
        verifyEmail()
        setLoading(false)
    };

    useEffect(() => {
        if (!loading) {
            verifyToken();
        }
    }, []);

    if (loading) {
        return (
            <Wrapper className='page'>
                <h4>There was an error, please double check your verification link</h4>
            </Wrapper>
        );
    }

    return (
        <Wrapper className='full-page'>
            <form className="form">
                <h2>Account Confirmed</h2>
                <Link to='/login' className='btn'>
                    Please login
                </Link>
            </form>
        </Wrapper>
    );
};

export default VerifyPage;